function subFormatDate (timeStr, formatStr) {
  let str = timeStr;
  if (str.indexOf("+08:00") !== -1) {
    str = str.replace(/-/g, '/').replace(/T/g, ' ').replace(/Z/g, '');
    str = str.substr(0, str.indexOf('+'));
  } else if (str.indexOf(".") !== -1) {
    str = str.replace(/-/g, '/').replace(/T/g, ' ').replace(/Z/g, '');
    str = str.substr(0, str.indexOf('.'));
  } else {
    str = str.replace(/-/g, '/')
  }

  return formatDate(str, formatStr);
}

function IOSFormtDate (timeStr) {
  let str = timeStr;
  if (str.indexOf(".000Z") !== -1) {
    // 拿到数据的时候使用
    str = str.replace(/.000Z/g, '+08:00');
  } else if (str.indexOf(" ") !== -1) {
    //表单提交的时候使用
    let arr = str.split(" ");
    str = arr.join('T');
    str = str + '+08:00';
  }
  return str;
}

/**
 * 格式化日期
 * @param {string | number | Date} vtimelue 指定日期
 * @param {string} formatStr 格式化的规则
 * formatDate(1603264465956, "Y年M月D日");
 */
function formatDate (time, formatStr='yyyy-MM-dd hh:mm:ss') {
  if (typeof time === "string") {
    time.replace(/-/g, '/')
  }
  let date = new Date(time);
  var o = {
    "M+": date.getMonth() + 1, //月份 
    "d+": date.getDate(), //日 
    "h+": date.getHours(), //小时 
    "m+": date.getMinutes(), //分 
    "s+": date.getSeconds(), //秒 
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
    "S": date.getMilliseconds() //毫秒 
};
if (/(y+)/.test(formatStr)) formatStr = formatStr.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
for (var k in o)
if (new RegExp("(" + k + ")").test(formatStr)) formatStr = formatStr.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
return formatStr;
}


export default {
  /**
   * 判断设备是否为 IOS
   */
  isIos() {
    let u = navigator.userAgent;
    return (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1)
  },

  /** 
   *拼接请求url的参数
  */
  joinParams (baseUrl, params) {
    let paramsArray = [];
    Object.keys(params).forEach(key => paramsArray.push(key + "=" + params[key]));
    if (baseUrl.indexOf("?") != -1) {
      baseUrl += "&" + paramsArray.join("&");
    } else {
      baseUrl += "?" + paramsArray.join("&");
    }
    return baseUrl;
  },

  /**
   * 删除URL中指定search参数,会将参数值一起删除
   * @param {string} url 地址字符串
   * @param {array} aParam 要删除的参数key数组，如['name','age']
   * @return {string} 返回新URL字符串
   */
  ridUrlParam(url, params) {
    for (let index = 0; index < params.length; index++) {
      let item = params[index];
      let fromIndex = url.indexOf(item + "="); //必须加=号，避免参数值中包含item字符串
      if (fromIndex !== -1) {
        // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
        let startIndex = url.indexOf("=", fromIndex);
        let endIndex = url.indexOf("&", fromIndex);
        let hashIndex = url.indexOf("#", fromIndex);
  
        let reg = "";
        if (endIndex !== -1) {
          // 后面还有search参数的情况
          let num = endIndex - startIndex;
          reg = new RegExp(item + "=.{" + num + "}");
          url = url.replace(reg, "");
        } else if (hashIndex !== -1) {
          // 有hash参数的情况
          let num = hashIndex - startIndex - 1;
          reg = new RegExp("&?" + item + "=.{" + num + "}");
          url = url.replace(reg, "");
        } else {
          // search参数在最后或只有一个参数的情况
          reg = new RegExp("&?" + item + "=.+");
          url = url.replace(reg, "");
        }
      }
    }
    let noSearchParam = url.indexOf("=");
    if (noSearchParam === -1) {
      url = url.replace(/\?/, ""); // 如果已经没有参数，删除？号
    }
    return url;
  },

  subFormatDate,
  formatDate,
  IOSFormtDate
}