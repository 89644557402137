import http from "../http";
import utils from "@/utils/utils";

export default {
  /* 获取openid */
  getWechatOpenId(params) {
    return http.get(`/wx_authorization/h5_authorization_userinfo`, params);
  },

  /* 获取微信配置 */
  getWxChatConfing (params) {
    let url = utils.joinParams('/wx_authorization/get_jssdk_config', params)
    return http.post(url);
  },

  /* 查询是否有未完成的订单 */
  getBeforepay (params) {
    return http.get(`/order/beforepay`, params);
  },

  /* 创建订单 */
  postCreateOrder (params) {
    return http.post(`/order/create`, params, {
      loading: true,
    });
  },

  /* 预下单 */
  postPrepay_id (params) {
    return http.post(`/order/pay/prepay`, params, {
      loading: true,
    });
  },

  /* 微信支付签名 */
  getWxPaySign (params) {
    return http.post(`/order/pay/prepay`, params, {
      loading: true,
    });
  },

  /* 设置订单支付状态 */
  setOrderStatus (params) {
    return http.post(`/order/update_paid_status`, params, {
      loading: true,
    });
  },

  /* 校验支付 */
  getVerifyOrderPay (params) {
    return http.get(`/order/pay/find_by_order_id`, params, {
      loading: true,
    });
  },


  /* 设置订单的查询信息 */
  setOrderBirthInfo (params) {
    return http.post(`/order/setinfo`, params, {
      loading: true,
    });
  },

  /* 获取订单的查询信息 */
  getOrderBirth (params) {
    return http.get(`/order/get_user_info`, params);
  },

  getChatrSvg (params)  {
    return http.get(`/chart`, params, {
      loading: true,
    })
  },

  getPlanetPosition (params) {
    return http.get(`/planet-position`, params)
  },

  getKundliMatching (params) {
    return http.get(`/kundli-matching/advanced`, params, {
      loading: true,
    })
  },

  getKundliAdvanced  (params) {
    return http.get(`/kundli/advanced`, params, {
      loading: true,
    })
  },
}