import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vant from "vant";
import "vant/lib/index.css";

import Wx from 'weixin-js-sdk';

import api from "./utils/http/api";

import CTitle from "components/CTitle.vue";
import "lib-flexible/flexible";
import "amfe-flexible";

// 测试 VConsole
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(CTitle);

Vue.prototype.$wx = Wx;
Vue.prototype.$api = api;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


