<template>
  <div>
    <van-nav-bar
      :title="title"
      :left-text="leftText"
      :left-arrow="showLeftIcon"
      v-on="$listener"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    backgroundColor: {
      type: String,
      default: '#FFFFFF'
    },
    leftText: {
      type: String,
    },
    showLeftIcon: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
    }
  },

  created(){},

  methods:{},
}
</script>
<style lang="less" scoped>
</style>