<template>
  <div>
    <van-tabbar v-model="active" :route="true">
      <van-tabbar-item to="/home" name="home" icon="eye-o"
        >占星</van-tabbar-item
      >
      <van-tabbar-item to="/article" name="article" icon="newspaper-o"
        >文章</van-tabbar-item
      >
      <van-tabbar-item to="/member" name="member" icon="friends-o"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "home",
    };
  },
  mounted () {
    this.$router.onReady(() => {
      let currentPage = this.$route.path;
      if (currentPage.indexOf("/home") > -1) {
        this.active = "home"
      } else if (currentPage.indexOf("/article") > -1) {
        this.active = "article"
      } else if (currentPage.indexOf("/member") > -1) {
        this.active = "member"
      }
    });

  },
};
</script>
<style lang="less" scoped></style>
