import request from "./axios";

const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   * @param header 请求头设置
   */
  get(url, params, options, header) {
    const config = {
      method: "get",
      url: url,
      header: header,
    };
    if (params) config.params = params;
    return request(config, options);
  },

  post(url, params, options, header) {
    const config = {
      method: "post",
      url: url,
      header: header,
    };
    if (params) config.data = params;
    return request(config, options);
  },

  put(url, params, options, header) {
    const config = {
      method: "put",
      url: url,
      header: header,
    };
    if (params) config.params = params;
    return request(config, options);
  },

  delete(url, params, options, header) {
    const config = {
      method: "delete",
      url: url,
      header: header,
    };
    if (params) config.params = params;
    return request(config, options);
  },
};

export default http;
