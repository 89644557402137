<template>
  <div id="app">
    <router-view/>
    <my-footer v-show="!$route.meta.hideFooter"></my-footer>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import myFooter from "components/Footer.vue";
export default {
  data() {
    return {
      appid: "wx80e4e9f15342e6cd", // "wx2562a06f4918d1c8", // 测试号appid
      showLoginPic: true,
      openid:null,
    };
  },
  mounted() {
    if (process.env.NODE_ENV == 'development') {    
      // 测试 start
      sessionStorage.setItem("uid", "1654435555942WkLb81Bj0gHVlDdXU");
      sessionStorage.setItem("openid", "oU2kT6UmNPCU3eEiG428kUV_cCCw");
      sessionStorage.setItem("userinfo", `{"openid":"oU2kT6UmNPCU3eEiG428kUV_cCCw","nickname":"小良子","sex":0,"language":"","city":"","province":"","country":"","headimgurl":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKPRk3Dfnfy2Y0tbJbtvyUI83viazX1r8xSN4ywiaN8uY2hNA7gFZDxHmddxh2D2ImPslaqOxAuXwxA/132","privilege":[]}`)
      // 测试 end
    } else if (process.env.NODE_ENV == 'production') {    
      let openid = sessionStorage.getItem("openid");
      if (!openid) {
        this.getCode();
      } else {
        this.openid = openid;
      }
    }
  },
  methods: {
    //静默授权
    getCode() { 
      this.code = "";
      let local = window.location.origin + "/";
      let scope = "snsapi_userinfo"; //静默授权 用户无感知
      this.code = this.getUrlCode().code;
      if (this.code == null || this.code === "") {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=${scope}#wechat_redirect`;
      } else {
        this.getOpenId(this.code);
      }
    },
    getUrlCode() {
      let fullUrl = window.location.href;
      let theRequest = new Object();
      if (fullUrl.indexOf("?") != -1) {
        var temp = fullUrl.split("?");
        let str = temp[1];
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    getOpenId(code) {
      this.$api.getWechatOpenId({code: code}).then(res => {
        let data = res.data;
        sessionStorage.setItem("uid", data.uid ? data.uid : '');
        sessionStorage.setItem("openid", data.openid ? data.openid : '');
        sessionStorage.setItem("userinfo", JSON.stringify(data.userinfo ? data.userinfo : {}));
      }).then((() => {
        if (!sessionStorage.getItem("openid")) {
          let _this = this;
          _this.$dialog.confirm({
            showCancelButton: true,
            title: "错误提示",
            message: "为获取到账号id，要重新获取吗？",
            confirmButtonText: "重新获取",
            cancelButtonText: "取消",
          }).then(() =>{
            let newLink = utils.ridUrlParam(window.location.href, ["code", "state"])
            console.log(newLink);
            window.location.href = newLink;
          });
        }
      }))
    },
  },
  components: {
    myFooter,
  },
};
</script>

<style lang="less">
@import "./css/index.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
