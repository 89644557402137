import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const Home = (resolve) => require(["@/views/home/index"], resolve);
const Article = (resolve) => require(["@/views/article/index"], resolve);
const Member = (resolve) => require(["@/views/member/index"], resolve);

// 占星首页
const indexRouter = [
  {
    path: "/home",
    name: "home",
    component: Home,
    redirect: "/home/openGame",
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "openGame",
        name: "openGame",
        component: () => import("../views/home/openGame/openGame.vue"),
      },
      {
        path: "selectFun",
        name: "selectFun",
        component: () => import("../views/home/selectFun/selectFun.vue"),
        meta: {
          title: "功能选择",
          hideFooter: true,
        },
      },
      {
        path: "infoForm/:order_sn/:product_id",
        name: "infoForm",
        component: () => import("../views/home/infoForm/infoForm.vue"),
        meta: {
          title: "占星信息",
          hideFooter: true,
        },
      },
      {
        path: "ordinaryAnalysis/:order_sn",
        name: "ordinaryAnalysis",
        component: () => import("../views/home/ordinaryAnalysis/ordinaryAnalysis.vue"),
        meta: {
          hideFooter: true,
          title: "占星详情"
        }
      },
      {
        path: "otherTable",
        name: "otherTable",
        component: () => import("../views/home/otherTable/otherTable.vue"),
        meta: {
          hideFooter: true,
          title: "宫位信息"
        }
      },
      {
        path: "luckyStone",
        name: "luckyStone",
        component: () => import("../views/home/luckyStone/luckyStone.vue"),
        meta: {
          hideFooter: true,
          title: "幸运物推荐"
        }
      },
      {
        path: "loveMatch/:order_sn",
        name: "loveMatch",
        component: () => import("../views/home/loveMatch/loveMatch.vue"),
        meta: {
          hideFooter: true,
          title: "恋爱匹配"
        }
      },
      {
        path: "introAuthor",
        name: "introAuthor",
        component: () => import("../views/home/introAuthor/introAuthor.vue"),
        meta: {
          hideFooter: true,
          title: "联系summer"
        }
      },
    ],
  },
];

// 文章列表
const articleRouter = [
  {
    path: "/article",
    name: "article",
    component: Article,
    meta: {
      title: "文章列表",
    },
  },
];

// 我的
const memberRouter = [
  {
    path: "/member",
    name: "member",
    component: Member,
    meta: {
      title: "我的",
    },
  },
];

const routes = [
  {
    path: "/",
    name: "",
    redirect: "/home/openGame",
  },
  ...indexRouter,
  ...articleRouter,
  ...memberRouter,
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
